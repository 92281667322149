import Link from 'next/link';
import { ButtonBaseType, buttonClasses } from './Button';

type Props = ButtonBaseType & {
  href: string;
};

export default function LinkButton({
  href,
  children,
  size = 'lg',
  className,
}: Props) {
  if (href.startsWith('/')) {
    return (
      <Link href={href} className={buttonClasses({ size, className })}>
        {children}
      </Link>
    );
  }
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className={buttonClasses({ size, className })}
    >
      {children}
    </a>
  );
}
