import CountryInput from '../Input/CountryInput';
import EmailInput from '../Input/EmailInput';
import FirstNameInput from '../Input/FirstNameInput';
import LastNameInput from '../Input/LastNameInput';
import PhoneInput from '../Input/PhoneInput';
import StreetInput from '../Input/StreetInput';
import ZipInput from '../Input/ZipInput';
import Disclaimer from '../FormSection/Disclaimer';
import LeadFormWrapper from './LeadFormWrapper';
import SubmitButton from '../Input/SubmitButton';

type Props = {
  prefix: string;
};

export default function VerticalLeadForm({ prefix }: Props) {
  return (
    <LeadFormWrapper
      prefix={prefix}
      className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
    >
      <FirstNameInput
        placeholder
        labelClassName="sr-only"
        inputClassName="bg-gray-100"
      />
      <LastNameInput
        placeholder
        labelClassName="sr-only"
        inputClassName="bg-gray-100"
      />
      <EmailInput
        placeholder
        labelClassName="sr-only"
        inputClassName="bg-gray-100"
      />
      <PhoneInput
        placeholder
        labelClassName="sr-only"
        inputClassName="bg-gray-100"
      />
      <StreetInput
        placeholder
        labelClassName="sr-only"
        inputClassName="bg-gray-100"
      />
      <CountryInput
        placeholder
        labelClassName="sr-only"
        inputClassName="bg-gray-100"
      />
      <ZipInput
        placeholder
        labelClassName="sr-only"
        inputClassName="bg-gray-100"
      />
      <div className="sm:col-span-2">
        <Disclaimer />
        <SubmitButton size="lg" />
      </div>
    </LeadFormWrapper>
  );
}
