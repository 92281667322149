import LeadFormWrapper from './LeadFormWrapper';
import { Wizard, useWizard } from 'react-use-wizard';
import PrimaryButton from '../Button/PrimaryButton';
import FirstNameInput from '../Input/FirstNameInput';
import LastNameInput from '../Input/LastNameInput';
import EmailInput from '../Input/EmailInput';
import PhoneInput from '../Input/PhoneInput';
import SubmitButton from '../Input/SubmitButton';
import Disclaimer from '../FormSection/Disclaimer';
import ZipInput from '../Input/ZipInput';
import StreetInput from '../Input/StreetInput';
import HiddenInput from '../Input/HiddenInput';
import CountryInput from '../Input/CountryInput';
import { useFormContext } from 'react-hook-form';

type StepProps = {
  question?: string;
  children: React.ReactNode;
};

function ProgressBar() {
  const { activeStep, stepCount } = useWizard();
  return (
    <div className="w-full h-2 mb-8 bg-primary-100 ">
      <div
        className="h-2 bg-primary"
        style={{ width: `${((activeStep + 1) / stepCount) * 100 - 4}%` }}
      />
    </div>
  );
}

function Step({ question, children }: StepProps) {
  const { activeStep, stepCount } = useWizard();
  return (
    <div className="w-full px-6 md:px-0">
      <h2 className="mb-6 text-xl font-bold text-center">
        Step {activeStep + 1} of {stepCount}
      </h2>
      <ProgressBar />
      {question && (
        <h3 className="my-6 text-xl font-bold text-center">{question}</h3>
      )}
      {children}
    </div>
  );
}

function ButtonContainer({ children }: { children: React.ReactNode }) {
  return (
    // <div className="flex flex-row flex-wrap justify-center w-full px-12 space-x-6">
    <div className="grid grid-cols-1 px-4 sm:px-12 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
      {children}
    </div>
  );
}
function LocationContainer({ children }: { children: React.ReactNode }) {
  return (
    // <div className="flex flex-row flex-wrap justify-center w-full px-12 space-x-6">
    <div className="grid grid-cols-1 px-4 sm:px-12 gap-y-6 sm:grid-cols-4 sm:gap-x-2">
      {children}
    </div>
  );
}

function Step1() {
  const { nextStep } = useWizard();
  const { setValue } = useFormContext();
  const onClick = (ownership: string) => {
    setValue('ownership', ownership);
    nextStep();
  };
  return (
    <Step question="Do you own or rent your property?">
      <ButtonContainer>
        <PrimaryButton
          onClick={() => onClick('Single Family')}
          size="lg"
          className="grow"
        >
          Own
        </PrimaryButton>
        <PrimaryButton
          onClick={() => onClick('Renter/Not Owner')}
          size="lg"
          className="grow"
        >
          Rent
        </PrimaryButton>
      </ButtonContainer>
    </Step>
  );
}
function Step2() {
  const { nextStep } = useWizard();
  const { setValue } = useFormContext();
  const onClick = (electricBill: number) => {
    setValue('electricBill', electricBill);
    nextStep();
  };
  return (
    <Step question="What is your average monthly electric bill?">
      <ButtonContainer>
        <PrimaryButton onClick={() => onClick(50)} size="lg" className="grow">
          $75 or less
        </PrimaryButton>
        <PrimaryButton onClick={() => onClick(125)} size="lg" className="grow">
          $75-149
        </PrimaryButton>
        <PrimaryButton onClick={() => onClick(175)} size="lg" className="grow">
          $150-199
        </PrimaryButton>
        <PrimaryButton onClick={() => onClick(225)} size="lg" className="grow">
          $200 or more
        </PrimaryButton>
      </ButtonContainer>
    </Step>
  );
}
function Step3() {
  const { nextStep } = useWizard();
  const {
    trigger,
    formState: { dirtyFields, errors },
  } = useFormContext();
  const enabled =
    dirtyFields.address?.street &&
    dirtyFields.address?.zip &&
    !errors.ownership &&
    !errors.electricBill;
  const onClick = async () => {
    const isValid = await trigger(['address.street', 'address.zip']);
    if (isValid) {
      nextStep();
    }
  };
  return (
    <Step question="Where is the property located?">
      <LocationContainer>
        <StreetInput
          containerClassName="col-span-4 xl:col-span-4"
          labelClassName="sr-only"
          inputClassName="bg-gray-100 text-gray-700"
          placeholder
        />
        <CountryInput
          placeholder
          labelClassName="sr-only"
          inputClassName="bg-gray-100 text-gray-700"
          containerClassName="col-span-4 xl:col-span-2"
        />
        <ZipInput
          labelClassName="sr-only"
          inputClassName="bg-gray-100 text-gray-700"
          placeholder
          containerClassName="col-span-4 xl:col-span-2"
        />
        <PrimaryButton
          disabled={!enabled}
          onClick={onClick}
          className="grow sm:col-start-2 sm:col-span-2"
          size="lg"
        >
          Last Step
        </PrimaryButton>
      </LocationContainer>
    </Step>
  );
}
function Step4() {
  return (
    <Step>
      <div className="grid grid-cols-1 px-4 gap-y-6 sm:grid-cols-2 sm:gap-x-4 sm:px-12">
        <FirstNameInput
          labelClassName="sr-only"
          inputClassName="bg-gray-100 text-gray-700"
          placeholder
        />
        <LastNameInput
          labelClassName="sr-only"
          inputClassName="bg-gray-100 text-gray-700"
          placeholder
        />
        <EmailInput
          containerClassName="sm:col-span-2"
          labelClassName="sr-only"
          inputClassName="bg-gray-100 text-gray-700"
          placeholder
        />
        <PhoneInput
          containerClassName="sm:col-span-2"
          labelClassName="sr-only"
          inputClassName="bg-gray-100 text-gray-700"
          placeholder
        />
        <div className="sm:col-span-2">
          <Disclaimer />
          <div className="text-center">
            <SubmitButton size="lg" />
          </div>

          <HiddenInput name="address.country" />
          <HiddenInput name="address.street" />
          <HiddenInput name="address.zip" />
          <HiddenInput name="electricBill" />
          <HiddenInput name="ownership" />
        </div>
      </div>
    </Step>
  );
}

type Props = {
  cid?: string;
};

export default function RollingLeadForm({ cid }: Props) {
  return (
    <LeadFormWrapper cid={cid} prefix="rolling">
      <Wizard>
        <Step1 />
        <Step2 />
        <Step3 />
        <Step4 />
      </Wizard>
    </LeadFormWrapper>
  );
}
