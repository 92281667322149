import { PHONE_DISPLAY, PHONE_NUMBER } from 'lib/constants';
import RollingLeadForm from './RollingLeadForm';

type Props = {
  cid?: string;
};

export default function RollingLeadFormSection({ cid }: Props) {
  return (
    <div className="py-12 bg-gray-100">
      <div className="w-11/12 p-4 mx-auto sm:m-4  md:mx-auto max-w-7xl lg:grid lg:grid-cols-2 bg-white rounded-[2rem]">
        <div className="grid gap-4 place-content-center max-w-prose mx-auto p-6">
          <h3 className="text-3xl font-bold text-center lg:text-left capitalize">
            Get a free quote
            <br />
            and EnergyPal offers.
          </h3>
          <p className="mt-2 text-base text-gray-900 text-center lg:text-left">
            Call{' '}
            <a
              href={`tel:${PHONE_NUMBER}`}
              className="font-bold underline text-primary"
            >
              {PHONE_DISPLAY}
            </a>{' '}
            or complete the questions and an EnergyPal Advisor will contact you
            with comparative solar quotes.
          </p>
        </div>
        <div className="px-4 py-8 sm:px-6 lg:col-span-1 lg:px-8 lg:py-24 xl:pr-12">
          <RollingLeadForm cid={cid} />
        </div>
      </div>
    </div>
  );
}
