import { useFormContext } from 'react-hook-form';

type Props = {
  name: string;
};

export default function HiddenInput({ name }: Props) {
  const { register } = useFormContext();
  return <input type="hidden" {...register(name)} />;
}
