import { PHONE_DISPLAY, PHONE_NUMBER } from 'lib/constants';
import VerticalLeadForm from './VerticalLeadForm';

type Props = {
  className?: string;
};

export default function VerticalLeadFormSection({
  className = 'bg-gray-100',
}: Props) {
  return (
    <div className={`py-8 ${className}`}>
      <div className="mx-auto max-w-7xl lg:grid lg:grid-cols-2">
        <div className="px-4 py-8 lg:px-24 lg:py-36 lg:col-span-1">
          <h3 className="text-3xl font-bold capitalize mb-4">
            Get a free quote
            <br />
            and EnergyPal offers.
          </h3>
          <p className="mt-2 text-base text-gray-900">
            Call{' '}
            <a
              href={`tel:${PHONE_NUMBER}`}
              className="font-bold underline text-primary"
            >
              {PHONE_DISPLAY}
            </a>{' '}
            or complete the questions and an EnergyPal Advisor will contact you
            with comparative quotes.
          </p>
        </div>
        <div className="px-4 py-8 sm:px-6 lg:col-span-1 lg:px-8 lg:py-16 xl:pr-12">
          <VerticalLeadForm prefix="vertical" />
        </div>
      </div>
    </div>
  );
}
